.underline {
    text-decoration: underline;
}

.cursor-pointer {
    cursor: pointer;
}

.pos-rel {
    position: relative;
}

.sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-1 {
    flex: 1
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

.focus-visible {
    outline-color: rgba(129, 202, 231, 0.3);
}

.header {
    background: rgb(237, 237, 237);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 10px;
    /* Fix for height bug with chat sidebar */
    min-height: 60px;
}

.avatar {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.emoji {
    background: url(../../assets/images/emoji-sprite.png) transparent;
    width: 40px;
    height: 40px;
    background-size: 400px;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    background-size: 500px;
}

/* Begin search input  */

.search-wrapper {
    padding: 7px 10px;
    height: 50px;
    background: #F6F6F6;
    position: relative;
}

.search-wrapper:focus-within {
    background: white;
}

.search {
    background: white;
    color: rgb(74, 74, 74);
    padding-left: 60px;
    border-radius: 18px;
    width: 100%;
    height: 100%;
}

.search::placeholder {
    color: rgb(153, 153, 153);
}

.search-icons {
    color: #919191;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    overflow: hidden;
}

.search-icon, .search__back-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.8s ease;
}

.search-icon {
    opacity: 1;
    transition-delay: 0.3s;
}

.search__back-btn {
    opacity: 0;
    transition-delay: 0.3s;
    color: rgb(51, 183, 246);
}

.search-wrapper:focus-within .search-icon {
    opacity: 0;
    transition-delay: 0s;
}

.search-wrapper:focus-within .search__back-btn {
    transform: rotate(360deg);
    opacity: 1;
    transition-delay: 0s;
}

/* End search input  */