.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 140%;
    left: 92%;
    transform: translateY(-50%);
  }
    
    .tooltip-text::after{
      content: "";
    position: absolute;
    top: 14%;
    right: 97%;
    margin-top: -5px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(-80deg);

    }
    .tooltip-container.show-tooltip:hover .tooltip-text {
      visibility: visible;
    }