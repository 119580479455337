/* @import url(darktheme.css); */
.loader {
  background: rgb(240, 240, 240);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader__logo-wrapper {
  position: relative;
}

.loader__logo-wrapper::after {
  content: "";
  position: absolute;
  left: -100px;
  bottom: 0;
  top: 0;
  width: 100px;
  background: linear-gradient(
    90deg,
    rgba(240, 240, 240, 0.5) 0,
    rgba(240, 240, 240, 0.5) 33.33%,
    rgba(240, 240, 240, 0) 44.1%,
    rgba(240, 240, 240, 0) 55.8%,
    rgba(240, 240, 240, 0.5) 66.66%,
    rgba(240, 240, 240, 0.5)
  );
  animation: glisten 2s ease-in 0.8s infinite;
}

.loader__logo {
  z-index: 3;
  fill: rgb(191, 191, 191);
  width: 60px;
  height: 60px;
}

.loader__progress {
  width: 200px;
  max-width: 400px;
  height: 4px;
  width: 90%;
  margin: 20px auto 30px;
  background: rgb(230, 230, 230);
  position: relative;
}

.loader__progress::after {
  content: "";
  position: absolute;
  height: 100%;
  background: #30b1c9;
  width: 0%;
  transition: width 2s linear;
}

.loader__progress--done::after {
  width: 100%;
}

.loader__title {
  color: #525252;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.loader__desc {
  color: rgba(0, 0, 0, 0.25);
  font-size: 0.85rem;
}

.loader__icon {
  margin-right: 5px;
}

@keyframes glisten {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(150px);
  }
}
